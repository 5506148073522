<!--

© 2020 – 2021 ProCSy JSC https://procsy.ru info@procsy.ru

© АО «ПроКСи», 2020 – 2021 info@procsy.ru

-->

<template>
  <CDropdown inNav class="c-header-nav-items" placement="bottom-end" add-menu-classes="pt-0">
    <template #toggler>
      <CHeaderNavLink>
        <BButton id="popover-reactive-1" ref="button" class="opacityBtn" @click="getTopics">
          <CIcon name="cil-bell" />
        </BButton>
      </CHeaderNavLink>
    </template>

    <CDropdownItem @click="selectOrg(org.id)" v-for="(item, i) in topics" :key="i">
      <CButton @click="itemClicked(item.topic_id)" class="buttonLikeRow">
        <b :class="isSuccessRound(item.success)"></b>
        <div>
          {{ $t(getType(item.type)) }} {{ $t("common.in") }}
          <span>{{ getDateFormatted(item.created) }}</span>
        </div>
      </CButton>
    </CDropdownItem>
  </CDropdown>
</template>

<script>
import { BButton } from "bootstrap-vue";
import moment from "moment";
import { mapGetters } from "vuex";
import utilMessages from "../../../utils/messages";
import { TopicStatus } from "./constants";

export default {
  name: "Topic",
  components: { BButton },
  data() {
    return {
      topicStatus: TopicStatus,
      fieldsForTable: [
        { key: "topic_id", label: "Topic id" },
        { key: "created", label: "Время завершения задачи" },
        { key: "type", label: "Тип задачи" },
      ],
      topicId: this.$route.params.id,
      result: null,
      topics: [],
      log: [],
      polling: null,
      isShowModal: false,
      popoverShow: false,
    };
  },
  async mounted() {
    this.getLog();
  },

  computed: {
    ...mapGetters(["getTopicFailed"]),
    status() {
      if (this.result) {
        return this.result.success
          ? this.topicStatus.success
          : this.topicStatus.error;
      } else {
        return this.topicStatus.loading;
      }
    },
  },
  methods: {
    async getLog() {
      try {
        await this.getTopics();
      } catch (err) {
        this.$toast.error(utilMessages.errMessage(err));
      }
    },
    async getTopics() {
      try {
        const result = await this.$store.dispatch("fetchTopics", this.topicId);
        if (result) {
          this.topics = result;
        }
        
        if (this.getTopicFailed) {
          await this.$store.dispatch("toggleTopicStatus");
        }
      } catch (err) {
        this.$toast.error(utilMessages.errMessage(err));
      }
    },
    getDateFormatted(dt) {
      if (dt) return moment(dt).format("HH:mm:ss");
    },
    itemClicked(topicId) {
      window.open(`/topics/${topicId}`);
      this.popoverShow = false;
    },
    getType(topic) {
      if (topic) {
        return this.$i18n.t(`message.${topic}`);
      }
      return "-";
    },
    onClose() {
      this.popoverShow = false;
    },
    isSuccessRound(isSuccess) {
      return isSuccess ? "green round" : "round";
    },
    onShow() {
      this.getTopics();
    },
    onShown() { },
    onHidden() {
      this.focusRef(this.$refs.button);
    },
    focusRef(ref) {
      this.$nextTick(() => {
        this.$nextTick(() => {
          (ref.$el || ref).focus();
        });
      });
    },
  },
};
</script>

<style scoped>
.back-button {
  padding-left: 0;
  padding-right: 0;
}

.back-button:focus {
  box-shadow: none;
}

.log-container {
  margin-top: 20px;
}

.opacityBtn {
  background: transparent;
  border: none;
  position: relative;
}

.opacityBtn b {
  display: block;
  width: 5px;
  height: 5px;
  position: absolute;
  top: 5px;
  right: 5px;
  background: red;
  border-radius: 5px;
  animation: fadeInFromNone 1s ease-out infinite;
}

.buttonLikeRow {
  display: flex;
  min-width: 300px;
}

.buttonLikeRow div span {
  font-size: 11px;
}

.buttonLikeRow,
.no-borderCard {
  background: transparent;
  border: none !important;
  padding: 0 !important;
}

.no-borderCard>div {
  padding: 0 0 10px !important;
}

.no-borderCard {
  justify-content: flex-start;
}

.no-borderCard div {
  display: flex;
  text-align: left;
  width: 230px;
  padding-right: 10px;
}

.round {
  width: 12px;
  height: 12px;
  border-radius: 6px;
  border: 1px solid #ccc;
  background: red;
  display: inline-block;
  margin-right: 5px;
  margin-top: 5px;
}

.round.green {
  background: green;
}

.dropdown-menu {
  height: 300px;
  width: 300px;
}

@keyframes fadeInFromNone {
  0% {
    display: none;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 1;
  }
}
</style>
