<!--

© 2020 – 2021 ProCSy JSC https://procsy.ru info@procsy.ru

© АО «ПроКСи», 2020 – 2021 info@procsy.ru

-->

<template>
  <CHeader fixed with-subheader light>
    <CToggler
      in-header
      class="ml-3 d-lg-none"
      @click="$store.commit('toggleSidebarMobile')"
    />
    <CToggler
      in-header
      class="ml-3 d-md-down-none"
      @click="$store.commit('toggleSidebarDesktop')"
    />
    <CHeaderBrand class="mx-auto d-lg-none" to="/">
      <img src="/img/logo-black.png" class="heightLogo" />
    </CHeaderBrand>
    <CHeaderNav class="d-md-down-none mr-auto"> </CHeaderNav>
    <CHeaderNav class="mr-4">
      <CButton type="button" @click="setLanguage('ru')" v-if="isEnglish()">
        <CIcon name="cif-us" size="xl" />
      </CButton>
      <CButton type="button" @click="setLanguage('en')" v-if="isRussian()">
        <CIcon name="cif-ru" size="xl" />
      </CButton>
      <CHeaderNavItem class="d-md-down-none mx-2">
        <Topics />
      </CHeaderNavItem>
      <TheHeaderDropdownAccnt />
    </CHeaderNav>
    <CSubheader class="px-3">
      <CBreadcrumbRouter class="border-0 mb-0" />
    </CSubheader>
  </CHeader>
</template>

<script>
import TheHeaderDropdownAccnt from "./TheHeaderDropdownAccnt";
import Topics from "./../views/hauberk/topics/Topics";

export default {
  name: "TheHeader",
  components: {
    TheHeaderDropdownAccnt,
    Topics,
  },
  data() {
    return {
      language: window.localStorage.language || "en",
    };
  },
  methods: {
    isEnglish() {
      return this.language === "en";
    },
    isRussian() {
      return this.language === "ru";
    },
    currentRoute() {
      return this.history && this.history.current;
    },
    setLanguage(lang) {
      this.language = lang;
      window.localStorage.setItem("language", lang);
      this.$router.go(this.$router.currentRoute);
    },
  },
};
</script>
<style scoped>
.heightLogo {
  height: 35px;
}
</style>
