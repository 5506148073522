/*

© 2020 – 2021 ProCSy JSC https://procsy.ru info@procsy.ru

© АО «ПроКСи», 2020 – 2021 info@procsy.ru

*/


export default (vueThis) => [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavItem',
        name: vueThis.$i18n.t('menu.main'),
        to: '/dashboard',
        icon: 'cil-speedometer'
      },
      {
        _name: 'CSidebarNavTitle',
        _children: [vueThis.$i18n.t('menu.segmentManagement')]
      },
      {
        _name: 'CSidebarNavItem',
        name: vueThis.$i18n.t('menu.networks'),
        to: '/networks',
        icon: 'cil-lan'
      },
      {
        _name: 'CSidebarNavItem',
        name: vueThis.$i18n.t('menu.channels'),
        to: '/channels',
        icon: 'cil-stream'
      },
      {
        _name: 'CSidebarNavItem',
        name: vueThis.$i18n.t('menu.services'),
        to: '/services',
        icon: 'cil-grid'
      },
      {
        _name: 'CSidebarNavItem',
        name: vueThis.$i18n.t('menu.smartContracts'),
        to: '/smart-contracts',
        icon: 'cil-clone'
      }
    ]
  }
]
