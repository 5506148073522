<!--

© 2020 – 2021 ProCSy JSC https://procsy.ru info@procsy.ru

© АО «ПроКСи», 2020 – 2021 info@procsy.ru

-->


<template>
  <CFooter :fixed="false">
    <div class="mfs-auto">
      <a href="http://procsy.ru" target="_blank">{{ $t('footer.plc') }} &laquo;{{ $t('footer.procsy') }}&raquo;</a>
      <span class="ml-1">&copy; {{new Date().getFullYear()}}</span>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'TheFooter'
}
</script>
