<!--

© 2020 – 2021 ProCSy JSC https://procsy.ru info@procsy.ru

© АО «ПроКСи», 2020 – 2021 info@procsy.ru

-->


<template>
  <CSidebar fixed :minimize="minimize" :show="show"
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])">
    <CSidebarBrand class="d-md-down-none" to="/">
      <img src="/img/logo.png" class='heightLogo'>
    </CSidebarBrand>

    <CRenderFunction flat :content-to-render="contentToRender" />
    <CSidebarMinimizer class="d-md-down-none" @click.native="$store.commit('set', ['sidebarMinimize', !minimize])" />
  </CSidebar>
</template>

<script>
import nav from './_nav'
import navAdmin from './_navAdmin'
import { mapGetters } from "vuex"

export default {
  name: 'TheSidebar',
  computed: {
    ...mapGetters([
      'isAdmin',
    ]),
    show() {
      return this.$store.state.sidebarShow
    },
    minimize() {
      return this.$store.state.sidebarMinimize
    },
    contentToRender() {
      return this.isAdmin ? navAdmin(this) : nav(this);
    }
  }
}
</script>
<style scoped>
.heightLogo {
  height: 35px;
}
</style>
