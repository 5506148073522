<!--

© 2020 – 2021 ProCSy JSC https://procsy.ru info@procsy.ru

© АО «ПроКСи», 2020 – 2021 info@procsy.ru

-->

<template>
  <div class="accounts-component-wrapper">
    <CDropdown inNav class="c-header-nav-items" placement="bottom-end" add-menu-classes="pt-0">
      <template #toggler>
        <CHeaderNavLink>
          <div class="c-avatar" v-if="getUser">
            {{ getUser.login }}@{{ currentOrg && currentOrg.mspid }}
          </div>
        </CHeaderNavLink>
      </template>
      <CDropdownItem @click="() => { logoutConfirmationVisible = true }">
        <CIcon name="cil-lock-unlocked" /> {{ $t('menu.logout') }}
      </CDropdownItem>

      <CDropdownItem @click="selectOrg(emptyGuid)" :class="{ 'selected-organization': currentOrg.id === emptyGuid }">
        <CIcon name="cil-lan" /> {{ $t('menu.networksManagement') }}
      </CDropdownItem>

      <CDropdownHeader tag="div" class="text-center" color="light" v-if="selectableOrgs.length">
        <strong>{{ $t('menu.switchOrganization') }}</strong>
      </CDropdownHeader>

      <template v-if="selectableOrgs.length">
        <CDropdownItem v-for="org in selectableOrgs" @click="selectOrg(org.id)" :key="org.id"
          :class="{ 'selected-organization': currentOrg.id === org.id }">
          <CIcon v-if="currentOrg.id === org.id" name="cil-check-circle" v-c-tooltip="$t('menu.currentOrganization')" />
          {{ org.name || org.mspid }}
        </CDropdownItem>
      </template>
    </CDropdown>

    <CModal alignment="center" 
      :show.sync="logoutConfirmationVisible" 
      :closeOnBackdrop="false"
      :title="$t('logout.title')"
      @close="() => { logoutConfirmationVisible = false }">
      <span>{{ $t('logout.message') }}</span>

      <div slot="footer" class="footer-buttons">
        <CButton type="button" class="btn btn-secondary mr-2" @click="closeModal"> {{ $t('logout.cancel') }} </CButton>
        <CButton type="button" class="btn btn-primary" @click="logout"> {{ $t('logout.confirm') }} </CButton>
      </div>
    </CModal>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

import utilMessages from "../utils/messages"
import { EMPTY_GUID } from "../const/commonConsts.js";
import { NetworkUpdateStatus } from "../views/hauberk/networks/constants"


export default {
  name: 'TheHeaderDropdownAccnt',
  data() {
    return {
      netUpdateStatus: NetworkUpdateStatus,
      emptyGuid: EMPTY_GUID,
      logoutConfirmationVisible: false
    }
  },

  computed: {
    ...mapGetters([
      'getOrganizations',
      'getUser',
      'isAdmin',
    ]),

    currentOrg() {
      return this.getOrganizations.find(org => org.id === this.getUser.org_id) || {}
    },

    selectableOrgs() {
      return this.getOrganizations.filter(org => org.id !== EMPTY_GUID)
    }
  },
  async mounted() {
    if (!await this.$store.dispatch('loadToken')) {
      this.$router.push({ path: `/login` });
    }

    await this.fetchOrganizaitons()
  },
  methods: {
    async logout() {
      await this.$store.dispatch('logout')
      this.$router.push({ path: `/login` })
    },
    closeModal() {
      this.logoutConfirmationVisible = false;
    },
    async fetchOrganizaitons() {
      try {
        this.$store.dispatch('fetchUserOrganizations')
      } catch (err) {
        this.$toast.error(utilMessages.errMessage(err))
      }
    },

    async selectOrg(orgId) {
      try {
        await this.$store.dispatch('switchOrganization', orgId)
        this.routeAfterSelectOrg()
      } catch (err) {
        this.$toast.error(utilMessages.errMessage(err))
      }
    },

    routeAfterSelectOrg() {
      if (this.isAdmin) {
        const path = this.$route.path.includes('networks')
          ? '/networks/list'
          : '/';
        this.$router.push({ path }).catch(() => { });
      } else {
        if (this.$route.path.includes('networks')) {
          if (this.getUser.blockchain_net_update_status === this.netUpdateStatus.success.code)
            this.$router.push({ path: `/networks/${this.getUser.blockchain_net_id}` }).catch(() => { });
          else
            this.$router.push({ path: `/networks/${this.getUser.blockchain_net_id}/draft` }).catch(() => { });
        }
        else if (this.$route.path.includes('endpoints'))
          this.$router.push('/services').catch(() => { });
        else if (this.$route.path.includes('channels') && !this.$route.path.includes('list'))
          this.$router.push('/channels').catch(() => { });
        else
          this.$router.go(this.$route);
      }
    }
  }
}
</script>

<style scoped>
.c-icon {
  margin-right: 0.3rem;
}

.c-avatar {
  width: auto;
}

.selected-organization {
  background-color: rgba(46, 184, 92, 0.2);
}
</style>